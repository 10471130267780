import axios from '../common/axios'

export async function createExpense(data) {
  return await axios.post('/expenses/create', data)
}

export function loadExpenses(params) {
  return axios.get('/expenses/find', {params})
}

export async function loadExpense(id) {
  return await axios.get(`/expenses/findOne`, {
    params: {_id: id},
  })
}

export async function deleteExpense(id) {
  return await axios.delete(`/expenses/delete`, {
    params: {_id: id},
  })
}

export async function updateExpense(data) {
  return await axios.put('/expenses/update', data, {
    params: {_id: data._id},
  })
}

export async function loadAdSpends(params) {
  return await axios.get('/spends/search', {params})
}

export async function loadSpend(id) {
  return await axios.get(`/spends/get`, {
    params: {_id: id},
  })
}

export async function deleteSpend(id) {
  return await axios.delete(`/spends/delete`, {
    params: {_id: id},
  })
}

export async function updateSpend(data) {
  return await axios.put('/spends/update', data, {
    params: {_id: data._id},
  })
}

export async function createSpend(data) {
  return await axios.post('/spends/create', data)
}
